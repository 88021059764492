/*
 © 2024 Missouri State Society. All rights reserved.
 U.S. Copyright Office Case No. 1-14163499371.
*/

// Purpose: Main App Component
import React from 'react';
import backgroundVideo from './background.mkv';
import './App.css';
import mossphoto1 from './moss-photo-1.png';
import schwartz from './schwartz.jpg';
import bader from './bader.jpg';
import thiessen from './thiessen.jpg';
import jag from './jag.jpg';
import boswell from './boswell.jpg';

function App() {
  return (
    <div className="App"> 
      {/* Header Section*/}
      {/* This section will include the nav bar, donate button, and text logo aligned left*/}
      <header className="App-header">   
        <video autoPlay muted loop playsinline className="background-video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag
        </video>
        
        <div className="absolute inset-0 bg-black opacity-10"></div> {/* Adding opacity over the video to make words legible.*/}


        <div className='header-content'>  
         <div className='header-title grid'>
          <span class="font-merriweather font-bold text-3xl">Missouri State Society</span>
          <span class="font-bodini italic text-2xl justify-self-end">of Washington, D.C.</span>
         </div>

        <nav className='header-nav'>
          <ul className='flex space-x-14'>
            <li><a class="link link-hover" href="#purpose" className="text-black">Purpose</a></li>
            <li><a class="link link-hover" href="#events" className="text-black">Events</a></li>
            <li><a class="link link-hover" href="#about" className="text-black">About</a></li>
            <li><a class="link link-hover" href="#join" className="text-black">Join</a></li>
          </ul>
        </nav>

          <div className='header-button'>
            <a href="https://www.paypal.com/paypalme/MissouriStateSociety" target="_blank">
            <button className="btn btn-primary">Membership</button></a>
          </div>

        </div> 
        <div className="header-purpose mx-auto max-w-3xl italic">
        <strong>A nonprofit, nonpartisan organization bringing together Missourians living in our nation's capital.</strong>
        </div>
      </header> 
      {/* Main Section*/}
      {/* This section will include About, Events, and Joining Information*/}
      <main className="App-body">
        <section id="purpose" className ="container mx-auto px-4 py-8">
          <div class="flex items-center justify-between">
            <div class="w-1/2">
              <img src={mossphoto1} alt="Description of the photo" class="rounded-lg shadow-lg"></img>
            </div>
            
            <div class="w-1/2 ml-8">
              <h2 class="text-3xl font-bold mb-4">Purpose</h2>
              <p class="text-lg text-gray-700">
                The Missouri State Society (MOSS) is a non-profit, non-political organization that brings together Missourians that live in or near the Nation's Capital. The MOSS is managed by a volunteer Executive Board and consists of a wide range of members, including Members of the Missouri Congressional Delegation, Capitol Hill staff, federal agency and professional association staff, attorneys, lobbyists, business associates, college students, and friends of Missouri. 
              </p>
            </div>
          </div>
        </section>

        <section id="events" className="container mx-auto px-4 py-8">
          <h2 class="text-3xl font-bold mb-4">Events</h2>
          <p class="text-lg text-gray-700">
            MOSS hosts a variety of events throughout the year, including happy hours,and networking events. These events are open to all members and friends of Missouri. 
          </p>
        </section>

        <section id="about" className="container mx-auto px-4 py-8">
          <h2 class="text-3xl font-bold mb-4">About</h2>
          <p class="text-lg text-gray-700 mb-4">
            The Missouri State Society, one of the oldest state societies in Washington, D.C., was founded in 1900 by a young Missourian named Joe Manlove who was working for the Census Bureau. Homesick, he placed an ad in the local newspaper requesting fellow Missourians to meet him at a local restaurant. When Joe arrived, the restaurant was filled with Missourians. At this first meeting, the group organized itself, electing Joe as the first President. 
          </p>

          <p class="text-lg text-black-700">
            <h2 class="text-3xl font-bold mb-4">2024 Leadership</h2> 
          </p>

          <div className="flex justify-center gap-x-16">
            <div className="text-center">
              <div className="avatar">
                <div className="w-24 rounded-full">
                  <img src={schwartz} alt="Hannah Schwartz" />
                </div>
              </div>
              <p className="App-body-title mt-2">President</p>
              <p className="App-body-name mt-2">Hannah Schwartz</p>
            </div>
            
            <div className="text-center">
              <div className="avatar">
                <div className="w-24 rounded-full">
                  <img src={bader} alt="David Bader" />
                </div>
              </div>
              <p className="App-body-title mt-2">Vice President</p>
              <p className="App-body-name mt-2">David Bader</p>
            </div>
            
            <div className="text-center">
              <div className="avatar">
                <div className="w-24 rounded-full">
                  <img src={thiessen} alt="Alex Thiessen" />
                </div>
              </div>
              <p className="App-body-title mt-2">Treasurer</p>
              <p className="App-body-name mt-2">Alex Thiessen</p>
            </div>
            
            <div className="text-center">
              <div className="avatar">
                <div className="w-24 rounded-full">
                  <img src={jag} alt="Rachel Jag" />
                </div>
              </div>
              <p className="App-body-title mt-2">President Emeritus</p>
              <p className="App-body-name mt-2">Rachel Jag</p>
            </div>
            
            <div className="text-center">
              <div className="avatar">
                <div className="w-24 rounded-full">
                  <img src={boswell} alt="Senn Boswell" />
                </div>
              </div>
              <p className="App-body-title mt-2">Secretary</p>
              <p className="App-body-name mt-2">Senn Boswell</p>
            </div>
          </div>
        </section>

        <section id="join" className="container mx-auto px-4 py-8">
          <h2 class="text-3xl font-bold mb-4">Join Us</h2>
          <p class="text-lg text-gray-700">
          As a member of the Missouri State Society, you'll join a dedicated group of Missourians working and serving in our nation's capital. Your annual membership dues ensure you receive free admission to many State Society-sponsored events throughout the year and free or reduced tickets at certain venues.</p>
<p class="text-lg text-gray-700">To join, fill out the <a href="https://www.google.com/url?q=https%3A%2F%2Fmissouristatesociety.us10.list-manage.com%2Fsubscribe%3Fu%3D0f97bc715872c4a5fa1a0025b%26id%3De092f2e8a2&sa=D" style={{ color: 'blue', textDecoration: 'underline' }}> following form </a> to ensure we have your information and send $25 to the Missouri State Society through Paypal.</p> 
          
          
          
          <a href="https://www.paypal.com/paypalme/MissouriStateSociety" target="_blank">
            <button className="btn btn-primary">Paypal</button></a>
        </section>




      </main>

      {/* Footer Section*/}
      {/* This section will include social media links, basic copyright information*/}
      <footer className="App-footer"> 

        <div class="mt-8 flex">
          <a href="https://www.facebook.com/groups/mostatesociety/" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="https://www.instagram.com/mostatesociety/" class="ml-6 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Instagram</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="https://twitter.com/mostatesociety" class="ml-6 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
        </div>

        <p class="pb-8 mt-8 text-base text-gray-400">© 2024 Missouri State Society of Washington, D.C. All rights reserved. U.S. Copyright Office Case No. 1-14163499371</p>


      </footer>

    </div>
  );
}

export default App;
